import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

// Connect to the Socket.IO server
const socket = io('http://localhost:3000'); // Replace with your server address if different

const App = () => {
    const [formData, setFormData] = useState('');
    const [receivedData, setReceivedData] = useState('');
    const [pageLoadData, setPageLoadData] = useState('');

    useEffect(() => {
        // Listen for 'syncFormData' event
        socket.on('syncFormData', (data) => {
            console.log('Received syncFormData:', data);
            setReceivedData(data);
        });

        // Listen for 'pageLoad' event
        socket.on('pageLoad', (data) => {
            console.log('Received pageLoad:', data);
            setPageLoadData(data);
        });

        // Cleanup on component unmount
        return () => {
            socket.off('syncFormData');
            socket.off('pageLoad');
        };
    }, []);

    const handleFormChange = (e) => {
        const data = e.target.value;
        setFormData(data);

        // Emit 'syncFormData' event
        socket.emit('syncFormData', data);
    };

    const handlePageLoad = () => {
        const data = 'Page loaded';
        socket.emit('pageLoad', data);
    };

    return (
        <div style={{ padding: '20px' }}>
            <h1>React + Socket.IO</h1>
            
            <div>
                <h2>Sync Form Data</h2>
                <input
                    type="text"
                    value={formData}
                    onChange={handleFormChange}
                    placeholder="Type something..."
                />
                <p>Synced Data: {receivedData}</p>
            </div>
            
            <div>
                <h2>Page Load Event</h2>
                <button onClick={handlePageLoad}>Simulate Page Load</button>
                <p>Page Load Data: {pageLoadData}</p>
            </div>
        </div>
    );
};

export default App;
